import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getCookie } from "../../../../Helper/Cokkies";
import jwt_decode from "jwt-decode";
import { adminNav, tlNav, userNav, hrNav } from "../../AllocationAssets/sideNavData";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { api } from "../../../../Helper/Data";

const Menu = () => {
  const count = useSelector((state) => state.CounterList.data);
  const [navLinks, setNavLinks] = useState([]);
  // const [count, setCount] = useState({})
  const token = getCookie("alcToken");
  var decode = jwt_decode(token);
  const role = decode.id.split("-")[1];
  const location = useLocation();
  const path = location.pathname.split("/");
  const param = path[path.length - 1];

  useEffect(() => {
    role === "admin" ? setNavLinks(adminNav) : role === "HR" ? setNavLinks(hrNav) : role === "TL" ? setNavLinks(tlNav) : setNavLinks(userNav);
  }, [role]);

  const countStyle = { borderRadius: "25px", fontSize: "12px", backgroundColor: "rgb(156, 39, 176)", padding: "0px 5px" }

  const menuOpen = (item) => {
    let data = item.linkItems.filter((el) => {
      return el.url === param;
    });
    return data.length ? true : false;
  };

  const getCount = (ele) => {
    const text = ele.toLowerCase().replaceAll(" ", "")
    if (count[text]) {
      return <span class="badge badge-pill badge-light text-dark px-1"><strong>{count[text]}</strong></span>
    } else {
      return ""
    }
  }

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a href="/allocation" className="brand-link bg-white" style={{ padding: "10px 8px" }}>
          <img src={api.logosUrl + "logomini.png"} alt="SA" style={{ opacity: ".8", margin: "0px 0px 0px 15px", height: "35px" }} />
          <span className="brand-text w-100">
            <img src={api.logosUrl + "logoText.png"} alt="SA" style={{ opacity: ".8", width: "150px" }} />
          </span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {navLinks.length
                ? navLinks.map((el, i) => {
                  return el.linkItems ? (
                    <li key={i} className={menuOpen(el) ? "nav-item menu-open" : "nav-item"}>
                      <Link to="#" className="nav-link">
                        <i className={"nav-icon " + el.icon} />
                        <Typography className="px-1">
                          {el.title} {getCount(el.title)}
                          <i className="right fas fa-angle-left" />
                        </Typography>
                      </Link>
                      <ul className={"nav nav-treeview"} style={{ display: menuOpen(el) ? "block" : "none" }}>
                        {el.linkItems.map((link, i) => {
                          return (
                            <li key={"link" + i} className="nav-item">
                              <NavLink className="nav-link" to={link.url}>
                                <i className={"text-sm " + link.icon} />
                                <Typography>{link.subTitle} {getCount(link.subTitle)}</Typography>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ) : (
                    <li key={i} className="nav-item">
                      <NavLink to={el.url} className="nav-link shadow-none">
                        <i className={"nav-icon " + el.icon} />
                        <Typography className="px-1">{el.title} </Typography><span className="float-right">{getCount(el.title + "list")}</span>
                      </NavLink>
                    </li>
                  );
                })
                : "Loading"}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default Menu;
