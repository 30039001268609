import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import MainHeader from './MainHeader'
import SearchHeader from './SearchHeader'
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  zIndex: "9999",
  top: 0,
  left: 0
};

function HeaderMenu() {
  const refAnimationInstance = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      fire()
    }, 2000);
  }, [])


  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26
    });

    makeShot(0.7, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.5, {
      spread: 120,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.6, {
      spread: 120
    });
  }, [makeShot]);

  return (
    <>
      {/* <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} /> */}
      <div className={classnames("fixed-top", "navbar-primary")} expand="lg">
        <MainHeader />
        <SearchHeader />
      </div>
    </>
  );
}
export default HeaderMenu;
