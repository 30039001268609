import React from "react";
import { Outlet } from "react-router-dom";
import PlanSticker from "../Components/StickerButtons/PlanSticker";
import CopyRight from "../Components/Footer/CopyRight/CopyRight";
import FooterLinks from "../Components/Footer/FooterLinks/FooterLinks";
import HeaderMenu from "../Components/Header/HeaderMenu/HeaderMenu";
import JoinBanner from "../Components/Header/JoinBanner/JoinBanner";
import QuickCallback from "../Components/QuickCallBack/QuickCallback";
import { useDispatch } from "react-redux";
import { mainReduxFunction } from '../../../components/Utils/mainRedux'
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import TawkTo from 'tawkto-react'

export default function PublicLayout() {
  const disPatch = useDispatch();

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    mainReduxFunction(disPatch)
    var tawk = new TawkTo("6396e736daff0e1306dc3388", "1gk2nec1l")

    tawk.onStatusChange((status) => 
    {
        // console.log(status)
    })
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <PlanSticker></PlanSticker>
      <ScrollToTop />
      <QuickCallback></QuickCallback>
      <HeaderMenu></HeaderMenu>
      <JoinBanner></JoinBanner> {/* for gap in header */}
      <Outlet></Outlet>
      <FooterLinks></FooterLinks>
      <CopyRight></CopyRight>
    </>
  );
}
