import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { getCookie } from "../../../../Helper/Cokkies";
const axios = require("axios").default;

function PurchasedLeads() {
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  let token = getCookie("token");

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Unique ID</strong>,
      selector: (row) => row.lead.leadId,
      sortable: true,
      width: "150px",
    },
    {
      name: <strong>Title</strong>,
      selector: (row) => row.lead.leadTitle,
      sortable: true,
      width: "250px",
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => row.lead.leadingName,
      sortable: true,
      width: "200px",
    },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.lead.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>Purchased On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
  ];

  useEffect(() => {
    if (currentCompanyUser.user) {
      axios
        .post(api.url + "/get-purchasedLeads-for-admin", { userId: currentCompanyUser._id }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response.data);
          response.data.leads.forEach((item, index) => {
            item.serial = index + 1;
          });
          setListData(response.data.leads);
          setFilterItems(response.data.leads);
          setPending(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [currentCompanyUser, token]);

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => item.keyWords && item.keyWords.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding:"0px 15px" }}>
        <table>
          <tr className="border-bottom">
            <th>Title</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.leadTitle} </strong></td>
          </tr>
          <tr className="border-bottom">
            <th>Message</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.leadMessage} </strong></td>
          </tr>
          <tr className="border-bottom">
            <th>Company Name</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.company} </strong></td>
          </tr>
          <tr className="border-bottom">
            <th>Contact Person</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.leadingName} </strong></td>
          </tr>
          <tr className="border-bottom">
            <th>Contact No.</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.contact} </strong></td>
          </tr>
          <tr className="border-bottom">
            <th>Email</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.email} </strong></td>
          </tr>
          <tr>
            <th>Country</th>
            <td><strong>: </strong> </td>
            <td className="mx-2"><strong> {data.lead.country} </strong></td>
          </tr>
        </table>
      </div>
    );
  };

  const expandItem = () => {
    return (
      <h3 className="text-danger m-1 ml-2">
        <i className="fas fa-caret-square-up fa-sm"></i>
      </h3>
    );
  };
  const collapseItem = () => {
    return (
      <h3 className="text-primary m-1 ml-2">
        <i className="fas fa-caret-square-down fa-sm"></i>
      </h3>
    );
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Leads List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <Input id="search" name="search" className="border-primary" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      <DataTable
        pagination
        persistTableHead
        highlightOnHover
        columns={columns}
        data={filterItems}
        progressPending={pending}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }}
      />
    </div>
  );
}

export default PurchasedLeads;
