import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Notfound from "../../../components/404/Notfound";
import AllocationLayoutMain from "./AllocationLayoutMain";
import Login from "../Views/Login/Login";
import { getCookie } from "../../../Helper/Cokkies";
import jwt_decode from "jwt-decode";
import ChangePassword from '../../../components/ChangePassword/ChangePassword'
import { Dashboard, NewParty, PartiesList, FollowUpParties, CallBackParties, ProspectiveParties, MaterializeParties, SearchParties, UnassignedParties, MergeUserCandidate, RequestedParties, Candidates, TeamLeader, Grouping, TeamList, AddMultiParties, AllocationList, ScheduledParties, AllocationAddLeads, SiteKeywords, AddPerformer, PartyStatus, EditPartyStatus, PartyLog, UpdateParty, ShiftParties, GenerateProforma, UpdateCandidateDetails, Attendance } from "../Views/Parties/index"
import ContactUs from '../Contactus/ContactUs'
import "./style.css";
import { userValidate } from '../../../components/Utils/Utils'
import Logout from "../Views/Login/Logout";
import { alcmainReduxFunction } from "../../../components/Utils/mainRedux";
import { useDispatch } from "react-redux";
import { api } from "../../../Helper/Data";
import { getLocalStorage } from "../../../Helper/LocalStorage";
import { getAllocationCount } from "../../../Redux/Counter";
import axios from "axios";
import PartyDetails from "../Views/Parties/PartyDetails";
import PartyLogsList from "../Views/Parties/PartyLogsList";


export default function AllocationLayout() {
  const disPatch = useDispatch();
  const user = getLocalStorage('alcUser');
  const token = getCookie("alcToken");
  const verify_admin = userValidate(['admin'], 'alcToken')
  const verify_candidate = userValidate(['user'], 'alcToken')
  const verify_admin_hr = userValidate(['admin', 'HR'], 'alcToken')

  useEffect(() => {
    alcmainReduxFunction(disPatch)
  }, [disPatch])

  if (token !== undefined) {
    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    axios
      .post(api.url + "/get-allocation-count", { id: user.id, role: role }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response);
        disPatch(getAllocationCount(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/" element={<AllocationLayoutMain />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />}></Route>

        {/* Users */}
        <Route path="change-password" element={verify_admin ? <ChangePassword /> : <Navigate replace to="/allocation/logout" />}></Route>

        {/* Products */}
        <Route path="team-leader" element={verify_admin ? <TeamLeader /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="candidates" element={verify_admin ? <Candidates /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="team-list" element={<TeamList />}></Route>
        <Route path="allocation-list/:username" element={!verify_candidate ? <AllocationList /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="user-update/:username" element={!verify_candidate ? <UpdateCandidateDetails /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="requested-parties" element={verify_admin_hr ? <RequestedParties /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="unassigned-parties" element={verify_admin_hr ? <UnassignedParties /> : <Navigate replace to="/allocation/logout" />}></Route>

        <Route path="contact-us" element={<ContactUs />}></Route>
        <Route path="new-party" element={<NewParty />}></Route>
        <Route path="parties-list" element={<PartiesList />}></Route>
        <Route path="fresh-list" element={<PartiesList />}></Route>
        <Route path="update-party/:id" element={<UpdateParty />}></Route>
        <Route path="followup-list" element={<FollowUpParties />}></Route>
        <Route path="callback-list" element={<CallBackParties />}></Route>
        <Route path="prospective-list" element={<ProspectiveParties />}></Route>
        <Route path="scheduled-list" element={<ScheduledParties />}></Route>
        <Route path="materialize-list" element={<MaterializeParties />}></Route>
        <Route path="add-lead" element={<AllocationAddLeads />}></Route>
        <Route path="search-parties" element={<SearchParties />}></Route>
        <Route path="parties-log" element={<PartyLogsList />}></Route>
        <Route path="view-logs/:id" element={<PartyLog/>}></Route>
        <Route path="generate-proforma" element={<GenerateProforma/>}></Route>
        <Route path="attendance" element={verify_admin ? <Attendance /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="user-candidate-group" element={verify_admin ? <Grouping /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="add-performer" element={verify_admin ? <AddPerformer /> : <Navigate replace to="/allocation/logout" />}></Route>

        <Route path="party-status" element={<PartyStatus />}></Route>
        <Route path="shift-parties" element={<ShiftParties />}></Route>
        <Route path="party-details/:id" element={<PartyDetails />}></Route>
        <Route path="add-multi-parties" element={<AddMultiParties />}></Route>
        <Route path="update-party-status/:id" element={verify_admin_hr ? <EditPartyStatus /> : <Navigate replace to="/allocation/logout" />}></Route>
        <Route path="merge-user-candidate" element={verify_admin_hr ? <MergeUserCandidate /> : <Navigate replace to="/allocation/logout" />}></Route>

        <Route path="site-keywords" element={<SiteKeywords />}></Route>
      </Route>
      <Route path="*" element={<Notfound />} />
      {/* <Route path="/404" element={<Notfound direction={"allocation"} />}></Route> */}
    </Routes>
  );
}
