

export const adminNav = [
  {
    title: "Dashboard",
    icon: "fas fa-columns text-center",
    url: "dashboard"
  },
  // {
  //   title: "New Party",
  //   icon: "fas fa-plus text-center",
  //   url: "new-party"
  // },
  {
    type: "hasTreeview",
    title: "Party Addition",
    icon: "fas fa-plus text-center",
    linkItems: [
      { subTitle: "New Party", icon: "far fa-circle nav-icon", url: "new-party" },
      { subTitle: "Add Multi Parties", icon: "far fa-circle nav-icon", url: "add-multi-parties" },
    ],
  },

  {
    type: "hasTreeview",
    title: "Users",
    icon: "fas fa-users text-center text-success",
    linkItems: [
      { subTitle: "Team Leader", icon: "far fa-circle nav-icon", url: "team-leader" },
      { subTitle: "Candidate", icon: "far fa-circle nav-icon", url: "candidates" },
    ],
  },
  // {
  //   title: "User Candidate Group",
  //   icon: "fas fa-users-cog text-center",
  //   url: "user-candidate-group"
  // },
  {
    type: "hasTreeview",
    title: "Parties",
    icon: "fas fa-user text-center text-warning",
    linkItems: [
      { subTitle: "Search Parties", icon: "far fa-circle nav-icon", url: "search-parties" },
      { subTitle: "Requested Parties", icon: "far fa-circle nav-icon", url: "requested-parties" },
      { subTitle: "Unassigned Parties", icon: "far fa-circle nav-icon", url: "unassigned-parties" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Other",
    icon: "fas fa-cubes text-center text-info",
    linkItems: [
      { subTitle: "Site Keywords", icon: "far fa-circle nav-icon", url: "site-keywords" },
      { subTitle: "Performers", icon: "far fa-circle nav-icon", url: "add-performer" },
      { subTitle: "Shift Multi Parties", icon: "far fa-circle nav-icon", url: "shift-parties" },
    ],
  },
  {
    title: "Proforma",
    icon: "fas fa-key text-center",
    url: "generate-proforma"
  },
  {
    title: "Merge User-Candidate",
    icon: "fas fa-key text-center",
    url: "merge-user-candidate"
  },
  {
    title: "Parties Log",
    icon: "fas fa-search-location text-center text-danger",
    url: "parties-log"
  },
  {
    title: "Party Status",
    icon: "fas fa-clipboard-list text-center text-success",
    url: "party-status"
  },
  {
    title: "Attendance",
    icon: "fas fa-calendar-alt text-center text-warning",
    url: "attendance"
  },
];

export const hrNav = [
  {
    title: "Dashboard",
    icon: "fas fa-columns text-center",
    url: "dashboard"
  },
  {
    title: "New Party",
    icon: "fas fa-plus text-center text-warning",
    url: "new-party"
  },
  {
    title: "Add Multi Parties", 
    icon: "far fa-circle text-cente text-success", 
    url: "add-multi-parties"
  },
  { title: "Candidate", icon: "fas fa-users text-cente", url: "candidates" },
  {
    title: "Search Parties",
    icon: "fas fa-search  text-center",
    url: "search-parties"
  },
  {
    title: "Requested Parties",
    icon: "fas fa-user-clock text-center text-primary",
    url: "requested-parties"
  },
  {
    title: "Unassigned Parties",
    icon: "fas fa-user-times  text-center text-primary",
    url: "unassigned-parties"
  },
  {
    title: "Site Keywords",
    icon: "fas fa-key text-center",
    url: "site-keywords"
  },
  { title: "Performers", icon: "fas fa-trophy text-center", url: "add-performer" },
  { title: "Shift Multi Parties", icon: "fas fa-exchange-alt text-center", url: "shift-parties" },
  {
    title: "Merge User-Candidate",
    icon: "fas fa-key text-center",
    url: "merge-user-candidate"
  },
  {
    title: "Proforma",
    icon: "fas fa-key text-center",
    url: "generate-proforma"
  },
  {
    title: "Parties Log",
    icon: "fas fa-search-location text-center text-danger",
    url: "parties-log"
  },
  {
    title: "Party Status",
    icon: "fas fa-clipboard-list text-center text-success",
    url: "party-status"
  },
  {
    title: "Attendance",
    icon: "fas fa-calendar-alt text-center text-info",
    url: "attendance"
  },
];

export const tlNav = [
  {
    title: "Dashboard",
    icon: "fas fa-columns text-center",
    url: "dashboard"
  },
  {
    title: "New Party",
    icon: "fas fa-plus text-center",
    url: "new-party"
  },
  {
    title: "All Parties",
    icon: "fas fa-handshake",
    url: "parties-list"
  },
  {
    title: "Fresh Parties",
    icon: "fas fa-plus-circle",
    url: "fresh-list"
  },
  {
    title: "Follow Up",
    icon: "fas fa-hand-point-right text-danger",
    url: "followup-list"
  },
  {
    title: "Call Back",
    icon: "fas fa-reply-all text-success",
    url: "callback-list"
  },
  {
    title: "Prospective",
    icon: "fas fa-comment-dollar ",
    url: "prospective-list"
  },
  {
    title: "Proforma",
    icon: "fas fa-key text-center",
    url: "generate-proforma"
  },
  {
    title: "Materialize",
    icon: "fas fa-tasks text-alert",
    url: "materialize-list"
  },
  {
    title: "Scheduled Parties",
    icon: "fas fa-calendar-day text-primary",
    url: "scheduled-list"
  },
  {
    title: "Team List",
    icon: "fas fa-sitemap ",
    url: "team-list"
  },
  {
    title: "Search Parties",
    icon: "fas fa-search ",
    url: "search-parties"
  },
  {
    title: "Parties Log",
    icon: "fas fa-search-location text-center text-danger",
    url: "parties-log"
  },
  {
    title: "Site Keywords",
    icon: "fas fa-key text-center",
    url: "site-keywords"
  },
];

export const userNav = [
  {
    title: "Dashboard",
    icon: "fas fa-columns text-center",
    url: "dashboard"
  },
  {
    title: "New Party",
    icon: "fas fa-plus text-center",
    url: "new-party"
  },
  {
    title: "All Parties",
    icon: "fas fa-handshake",
    url: "parties-list"
  },
  {
    title: "Follow Up",
    icon: "fas fa-hand-point-right text-danger",
    url: "followup-list"
  },
  {
    title: "Call Back",
    icon: "fas fa-reply-all text-success",
    url: "callback-list"
  },
  {
    title: "Prospective",
    icon: "fas fa-comment-dollar text-primary",
    url: "prospective-list"
  },
  {
    title: "Materialize",
    icon: "fas fa-tasks text-warning",
    url: "materialize-list"
  },
  {
    title: "Scheduled Parties",
    icon: "fas fa-calendar-day text-primary",
    url: "scheduled-list"
  },
  {
    title: "Add buy leads",
    icon: "fas fa-tasks",
    url: "add-lead"
  },
  {
    title: "Search Parties",
    icon: "fas fa-search text-success",
    url: "search-parties"
  },
  {
    title: "Proforma",
    icon: "fas fa-key text-center",
    url: "generate-proforma"
  },
  {
    title: "Parties Log",
    icon: "fas fa-search-location text-center text-danger",
    url: "parties-log"
  },
  {
    title: "Site Keywords",
    icon: "fas fa-key text-center text-warning",
    url: "site-keywords"
  },
];
