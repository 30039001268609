import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, Table, Spinner, FormText } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import {useLocation} from 'react-router-dom'
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { useSelector } from "react-redux";
import SelectJS from "../../../../components/Select/SelectJS";
import { formValidation } from "../../../../components/Utils/Utils";
import { getCookie } from "../../../../Helper/Cokkies";
const axios = require("axios").default;

function UpdateParty() {
  const location = useLocation()
  const countries = useSelector((state) => state.CountriesList.countries);
  const candidatesStore = useSelector((state) => state.CanditatesList.data);
  const tl = useSelector((state) => state.CanditatesList.tlData);
  const [submiting, setSubmiting] = useState(false)
  const user = getLocalStorage("alcUser");
  let token = getCookie("alcToken");
  const [formData, setFormData] = useState(location.state);

  useEffect(() => {
  }, [candidatesStore, tl])


  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getSelected = (select) => {
    setFormData({ ...formData, country: select.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setSubmiting(true)
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([formData.firstName, formData.email, formData.contact, formData.company, formData.companyAddress])) {
      if (filter.test(formData.email)) {
        axios
          .post(api.url + "/update-party", formData, { headers: { authorization: "Bearer " + token } })
          .then(function (response) {
            if (response.data.duplicate) {
              alert(response.data.message);
              setSubmiting(false)
            } else if (response.data.partyExceed) {
              alert(response.data.message);
              setSubmiting(false)
            } else {
              alert("Party have been Update Successfully");
              setFormData({ firstName: "", country: "India", contact: "", lastName: "", altContact: "", email: "", status: "Fresh", assignTo: formData.assignTo, company: "", remarks: "", createdBy: user, companyAddress: "", });
              setSubmiting(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setSubmiting(false)
        alert("Please enter valid email / contact");
      }
    } else {
      setSubmiting(false)
      alert("Please fill the required fields");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add New Party</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="firstName">
                First Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="firstName"
                name="firstName"
                maxLength="10"
                value={formData.firstName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter First Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="lastName">
                Last Name
              </Label>
              <Input
                bsSize="sm"
                id="lastName"
                name="lastName"
                maxLength="10"
                value={formData.lastName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Last Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Contact No."}
                title="10 digits number allowed"
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="company">
                Company Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Company Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="altContact">
                Alternate No.{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="altContact"
                name="altContact"
                value={formData.altContact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Alternate No."}
                type="text"
              />
              <FormText color="info">
                Note :- No. can be multiple & separate with comma ( " , " )
              </FormText>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="remarks">
                Remarks/Comment{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="remarks"
                name="remarks"
                value={formData.remarks}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter remarks/comment..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="country">
                Country{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} data={countries} defValue={formData.country} selected={getSelected} />
            </FormGroup>
           
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Company Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={"Enter company address"}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button className="btn-sm btn-main px-4" disabled={submiting} type="submit">
            Update Party {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
        </Form>
      </div>
    </div>
  );
}

export default UpdateParty;
