import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLayout from "./Modules/Admin/Admin-layout/AdminLayout";
import AllocationLayout from "./Modules/Allocation/Allocation-layout/AllocationLayout";
import PublicLayout from "./Modules/Public/public-layout/PublicLayout";
import BlogRoutes from './Modules/Blogs/BlogLayout/BlogRoutes'
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { useEffect, useState } from "react";
import Notfound from "./components/404/Notfound";
import Home from "./Modules/Public/Views/Home/Home";
import Membershipplans from "./Modules/Public/Views/MembershipPlans/Membershipplans";
import Aboutus from "./Modules/Public/Views/Aboutus/Aboutus";
import Contactus from "./Modules/Public/Views/Contactus/Contactus";
import Privacypolicy from "./Modules/Public/Views/Privacypolicy/Privacypolicy";
import Termconditions from "./Modules/Public/Views/Termcondition/Termcondition";
import Jobcareer from "./Modules/Public/Views/Jobcareer/Jobcareer";
import Testimonial from "./Modules/Public/Views/Testimonial/Testimonial";
import ProductCategories from "./Modules/Public/Views/Products/ProductCategories";
import Companies from "./Modules/Public/Views/Companies/Companies";
import ProductView from "./Modules/Public/Views/Products/ProductView";
import RequirementFormPage from "./Modules/Public/Views/RequirementFormPage/RequirementFormPage";
import Faq from "./Modules/Public/Views/Faq/Faq";
import JoinformPage from "./Modules/Public/Views/JoinformPage/JoinformPage";
import Buyers from "./Modules/Public/Views/Buyers/Buyers";
import SearchLeads from "./Modules/Public/Views/Search/SearchLeads";
import SearchCompanies from "./Modules/Public/Views/Search/SearchCompanies";
import SearchProducts from "./Modules/Public/Views/Search/SearchProducts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'sweetalert2/src/sweetalert2'
import { getCookie } from "./Helper/Cokkies";
import { getLocalStorage } from "./Helper/LocalStorage";
import { setContries } from "./Redux/Countries";
import { useDispatch } from "react-redux";
import axios from "axios";
import CatDirectory from "./Modules/Public/Views/CatDirectory/CatDirectory";
import ProductDetails from "./Modules/Public/Views/Products/ProductDetails";
import LeadDetail from "Modules/Public/Views/Buyers/LeadDetail";
import CountryPage from "Modules/Public/Views/Country/CountryPage";
import CompanyTemplate from "./Modules/Public/Views/Companies/CompanyTemplate";
import PaymentForm from "./Modules/Payment/PaymentForm";
import InvoicePage from "Modules/Invoice/InvoicePage";

function App() {
  const user = getLocalStorage("user");
  const disPatch = useDispatch();
  const token = getCookie("token");
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    axios
      .get("/dist/docs/countries.json")
      .then(function (response) {
        const countriesData = response.data.map((el) => {
          return { value: el.name, label: el.name, flag: el.flag, code : el.callingCodes[0] };
        });
        disPatch(setContries(countriesData));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [disPatch, token, user]);

  var correctDataHandler = function(transition) {
    const condition = false
    condition ? transition.redirect('/') : transition.redirect('/admin')
};


  return (
    <BrowserRouter>
      {showButton ? (
        <div className="scroll-to-top-show">
          <ScrollToTop></ScrollToTop>
        </div>
      ) : (
        <div className="scroll-to-top-hide">
          <ScrollToTop></ScrollToTop>
        </div>
      )}

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicLayout />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/country/:countryCode" element={<CountryPage />}></Route>
          <Route path="/membership-plans" element={<Membershipplans />}></Route>
          <Route path="/about-us" element={<Aboutus />}></Route>
          <Route path="/contact-us" element={<Contactus />}></Route>
          <Route path="/privacy-policy" element={<Privacypolicy />}></Route>
          <Route path="/term-conditions" element={<Termconditions />}></Route>
          <Route path="/job-career" element={<Jobcareer />}></Route>
          <Route path="/requirement-form" element={<RequirementFormPage />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/testimonial" element={<Testimonial />}></Route>
          <Route path="/join-form" element={<JoinformPage />}></Route>
          <Route path="/sell-your-product" element={<JoinformPage />}></Route>
          <Route path="/product-categories" element={<ProductCategories />}></Route>
          <Route path="/products" element={<ProductView />}></Route>
          <Route path="/product-details/:companyLink/:productLink" element={<ProductDetails />}></Route>
          <Route path="/search-products/:key" element={<SearchProducts />}></Route>
          <Route path="/directory/:cat" element={<CatDirectory />}></Route>

          <Route path="/companies" element={<Companies />}></Route>
          <Route path="/search-companies/:key" element={<SearchCompanies />}></Route>

          <Route path="/importers" element={<Buyers />}></Route>
          <Route path="/buyers" element={<Buyers />}></Route>
          <Route path="/importer-details/:id" element={<LeadDetail />}></Route>
          <Route path="/search-importers/:key" element={<SearchLeads />}></Route>
        </Route>
        <Route path="*" element={<Notfound />} />
        <Route path="/company/:catalogLink" element={<CompanyTemplate />}></Route>
        {/* admin Route */}
        <Route path="/admin/*" element={<AdminLayout />}></Route>
        <Route path="/blogs/*" element={<BlogRoutes />}></Route>
        <Route path="/allocation/*" element={<AllocationLayout />} onEnter={ correctDataHandler } ></Route>
        <Route path="/payment" element={<PaymentForm />}></Route>
        <Route path="/invoice/:invoiceNo" element={<InvoicePage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
